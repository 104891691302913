html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  // Application is responsive until 1300px
  min-width: 1300px;
  overflow: hidden;
}

// a {
//   color: inherit; /* blue colors for links too */
//   text-decoration: inherit; /* no underline */
// }

a:hover {
  text-decoration: none;
}

a:link {
  text-decoration: none;
}

.CircularProgressbar-trail {
  opacity: 0.75;
}

.pointer {
  cursor: pointer;
}

.fade-in {
  transition: opacity 1s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}

.center-location {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
}

// global wrapper for page layouts
.page-wrapper {
  padding: 24px 48px 24px 48px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 8px !important;
  margin: 0 2px;
  height: 8px;
  cursor: pointer !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #c9d3e6;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a6a6a6;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a6a6a6;
}

// select withoud backdrop in network table
#menu-target_type > .MuiBackdrop-root {
  background-color: unset;
  opacity: unset;
}

.MuiBackdrop-root {
  background-color: '#000000';
  opacity: 0.25 !important;
}

.cursor {
  cursor: pointer;
}

.header-centered {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
